import React from 'react';
import { Link } from "gatsby";
import Layout from '../components/layout';
import Seo from '../components/seo';

const SignUpPage = () => {
    return (
        <Layout>
            <Seo title="Zapisy"/>
            <section className='signup-container'>
                <section className='signup-hero'>
                    <div className='signup-hero_text'>
                        <h1 className='signup-hero_title'>Pierwsza wizyta?</h1>
                        <p className='signup-hero_description'>Sprawdź, jak zarejestrować się na pierwszy trening
                        </p>
                    </div>
                    <div className='signup-hero_link-container'>
                        <Link to='/pierwsza-wizyta' className='signup-hero_link-link'>Szczegóły rejestracji</Link>
                    </div>
                </section>
                <section className='signup-hero return'>
                    <div className='signup-hero_text'>
                        <h1 className='signup-hero_title'>Jesteś już naszym Klientem?</h1>
                        <p className='signup-hero_description'>Przejdź do Twojego grafiku</p>
                    </div>
                    <div className='signup-hero_link-container_return'>
                        <a className='signup-hero_link-link_return' href='https://app.fitssey.com/pilatesterapeutyczny/frontoffice' target='_blank'>Twój grafik</a>
                    </div>
                </section>
            </section>
        </Layout>
    )
}

export default SignUpPage;